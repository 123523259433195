<template>
    <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">Download CURET</font></div></td>
          </tr>
          <tr>
            <td valign="baseline"><ul>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample01-02-03.tar.gz">Samples 1-3</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample04-05-06.tar.gz">Samples 4-6</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample07-08-09.tar.gz">Samples 7-9</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample10-11-12.tar.gz">Samples 10-12</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample13-14-15.tar.gz">Samples 13-15</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample16-17-18.tar.gz">Samples 16-18</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample19-20-21.tar.gz">Samples 19-21</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample22-23-24.tar.gz">Samples 22-24</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample25-26-27.tar.gz">Samples 24-27</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample28-29-30.tar.gz">Samples 28-30</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample31-32-33.tar.gz">Samples 31-33</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample34-35-36.tar.gz">Samples 34-36</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample37-38-39.tar.gz">Samples 37-39</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample40-41-42.tar.gz">Samples 40-42</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample43-44-45.tar.gz">Samples 43-45</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample46-47-48.tar.gz">Samples 46-48</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample49-50-51.tar.gz">Samples 49-51</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample52-53-54.tar.gz">Samples 52-54</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample55-56-57.tar.gz">Samples 54-57</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample58-59-60.tar.gz">Samples 58-60</a></li>
                <li><a href="https://cave.cs.columbia.edu/old/exclude/curet/dataComp/sample61.tar.gz">Sample 61</a></li>
                </ul>
            </td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p><router-link to="/repository/CURET"> [Return to Database Home Page] </router-link></p>
                  </div>
            </div></td>
          </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>